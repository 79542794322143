import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

class LoSentimosTrust extends Component {
    constructor(props) {
        super(props)
    }

    // function LoSentimosAfi({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(LoSentimosTrust, 'VDPNLoSentimosTrust')
LoSentimosTrust.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
LoSentimosTrust.defaultProps = {
    companyName: "Afi",
    dataInfo: {
        "textHeader": "Muchas gracias por tu interés en obtener un préstamo con nosotros",
        "textBox": "En este momento no fue posible validar tu número de teléfono celular. Espera una llamada por parte de uno de nuestros ejecutivos para continuar con el proceso de solicitud de préstamo.",
        "textBoxTwo": "Si tienes preguntas puedes llamar al 800-891-2778.",
        "textBoxThree": "Gracias."
    }
}
// export default LoSentimosAfi

